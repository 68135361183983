import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import LinkIcon from '../../static/assets/icons/link-icon.svg'

const MeetGraduatesInfoAndImageContainersContainer = styled.div`
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	@media (min-width: 768px) {
		grid-gap: 48px;
	}
`

const MeetGraduatesInfoAndImageContainer = styled.div`
	width: 100%;
	grid-column: 1 / span 12;

	& > div {
		grid-row: 1;
		grid-column: 1 / span 12;
	}

	&:nth-child(n + 1) {
		display: none;
	}

	&:nth-child(1) {
		display: block;
	}

	@media (min-width: 768px) {
		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3) {
			display: block;
			grid-row: 1;
			grid-column: 4 / span 6;
		}

		&:nth-child(2) {
			transform: translateX(40vw) translateY(-60px);
		}
		&:nth-child(3) {
			transform: translateX(-40vw) translateY(-60px);
		}
	}

	@media (min-width: 1200px) {
		&:nth-child(n) {
			display: block;
			grid-row: 1;
			grid-column: 5 / span 4;
		}

		&:nth-child(2) {
			transform: translateX(27.5vw) translateY(-60px);
		}
		&:nth-child(3) {
			transform: translateX(55vw);
		}
		&:nth-child(4) {
			transform: translateX(-27.5vw) translateY(-60px);
		}
		&:nth-child(5) {
			transform: translateX(-55vw);
		}
	}
`

const MeetGraduatesInfoContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	margin-bottom: 36px;
`

const MeetGraduatesInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	& > h4 {
		margin: 0;
		font-family: @PrimaryText;
		font-size: 24px;
		font-weight: normal;
		color: #3c4043;
		line-height: calc(36 / 24);
		letter-spacing: 0.15px;
	}
	& > p {
		margin: 0;
		font-family: @SecondaryText;
		font-size: 16px;
		font-weight: normal;
		color: #818181;
		line-height: calc(21 / 16);
		letter-spacing: 0.1px;
	}
`
const LinkWrapper = styled.div`
	& > * {
		border: 1px solid black;
		padding: 12px 36px 10px;
		border-radius: 30px;
		font-family: @SecondaryText;
		font-size: 16px;
		font-weight: normal;
		line-height: calc(16 / 16);
		letter-spacing: 0.1px;
		transition: all 100ms ease-in-out;

		&:hover,
		&:focus,
		&:active {
			color: white;
			/* font-weight: bold; */
			background: #00dccc;
			border: 1px solid #00dccc;
		}
	}
`

const GraduatesBanner = () => (
	<StaticQuery
		query={graphql`
			query {
				allMarkdownRemark(
					filter: { fileAbsolutePath: { regex: "/graduates/" } }
				) {
					edges {
						node {
							frontmatter {
								path
								name
								cover {
									childImageSharp {
										fluid(maxWidth: 480, quality: 70) {
											...GatsbyImageSharpFluid
										}
									}
								}
								field
							}
						}
					}
				}
			}
		`}
		render={data => (
			<React.Fragment>
				<MeetGraduatesInfoAndImageContainersContainer>
					{[
						data.allMarkdownRemark.edges[
							Math.floor(
								Math.random() *
									data.allMarkdownRemark.edges.length,
							)
						],
						data.allMarkdownRemark.edges[
							Math.floor(
								Math.random() *
									data.allMarkdownRemark.edges.length,
							)
						],
						data.allMarkdownRemark.edges[
							Math.floor(
								Math.random() *
									data.allMarkdownRemark.edges.length,
							)
						],
						data.allMarkdownRemark.edges[
							Math.floor(
								Math.random() *
									data.allMarkdownRemark.edges.length,
							)
						],
						data.allMarkdownRemark.edges[
							Math.floor(
								Math.random() *
									data.allMarkdownRemark.edges.length,
							)
						],
					].map(({ node }, index) => (
						<MeetGraduatesInfoAndImageContainer key={index}>
							<Img
								style={{
									// width: '20vw',
									// height: 'auto',
									marginBottom: '12px',
								}}
								fluid={
									node.frontmatter.cover.childImageSharp.fluid
								}
								alt={`A photo of ${node.frontmatter.name}`}
							/>
							<MeetGraduatesInfoContainer>
								<MeetGraduatesInfo>
									<h4>{node.frontmatter.name}</h4>
									<p>{node.frontmatter.field}</p>
								</MeetGraduatesInfo>
								<Link to={node.frontmatter.path}>
									<img
										src={LinkIcon}
										style={{
											width: '19px',
											transform: 'translateY(50%)',
										}}
										alt="link icon to student profile"
									/>
								</Link>
							</MeetGraduatesInfoContainer>
						</MeetGraduatesInfoAndImageContainer>
					))}
				</MeetGraduatesInfoAndImageContainersContainer>
				<LinkWrapper>
					<Link to="/graduates/">Come Meet the Grads</Link>
				</LinkWrapper>
			</React.Fragment>
		)}
	/>
)

export default GraduatesBanner
