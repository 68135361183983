import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'gatsby'
import Fields from './Field'
import style from './style.module.less'
import Img from 'gatsby-image'

export default class Profile extends PureComponent {
	render() {
		const { name, bio, cover, portfolio, field } = this.props

		const fields = field.split(',')
		const fieldItem = fields.map(field => (
			<Fields key={field} label={field} />
		))

		// console.log(fieldItem)

		return (
			<div className={style.profile}>
				<div className={style.info}>
					<h1 className={style.name}>{name}</h1>
					<div className={style.fieldColumn}>{fieldItem}</div>
					<p>{bio}</p>
					<div className={style.ctaContainer}>
						{portfolio === null ? null : (
							<a
								className={style.cta}
								href={portfolio}
								target="_blank"
								rel="noopener noreferrer"
							>
								View Portfolio
							</a>
						)}
					</div>
				</div>

				<div className={style.coverContainer}>
					<Img className={style.cover} fluid={cover} />
				</div>
			</div>
		)
	}
}

Profile.propTypes = {
	name: PropTypes.string.isRequired,
	field: PropTypes.string,
	bio: PropTypes.string.isRequired,
	cover: PropTypes.object.isRequired,
	portfolio: PropTypes.string.isRequired,
}
