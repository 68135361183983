import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.less'

export default class Fields extends PureComponent {
	render() {
		const { label } = this.props

		return <div className={style.borderless}>{label}</div>
	}
}

Fields.propTypes = {
	label: PropTypes.string.isRequired,
}
