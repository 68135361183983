import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Spring } from 'react-spring/renderprops'
import style from './blogStyles.module.less'
import Layout from '../../components/layout'
import Profile from '../../components/Profile'
import Banner from '../../components/Banner'
import LinkedInIcon from '../../../static/assets/icons/linkedin-icon.svg'
import EmailIcon from '../../../static/assets/icons/email-icon.svg'
import InstagramIcon from '../../../static/assets/icons/instagram-icon-black.svg'
import Img from 'gatsby-image'
import '../../utils/constant.less'
import GraduatesBanner from '../../components/graduates-banner'

export default function Template({ data }) {
	const { markdownRemark } = data
	const { frontmatter } = markdownRemark

	return (
		<Layout>
			<Spring
				config={{ tension: 320, friction: 30, clamp: true }}
				from={{ opacity: 0, paddingTop: 200 }}
				to={{ opacity: 1, paddingTop: 0 }}
			>
				{props => (
					<div className={style.scroll} style={props}>
						<div className={style.profileContainer}>
							<Profile
								name={frontmatter.name}
								bio={frontmatter.bio}
								cover={frontmatter.cover.childImageSharp.fluid}
								portfolio={frontmatter.portfolio}
								field={frontmatter.field}
							/>
						</div>

						<div className={style.social}>
							{frontmatter.email === null ? null : (
								<a
									className={style.socialLink}
									href={
										'mailto:' +
										frontmatter.email +
										'?body=Hi ' +
										frontmatter.name.split(' ')[0] +
										','
									}
								>
									<img
										className={style.socialIcon}
										alt="Email Icon"
										src={EmailIcon}
									/>
								</a>
							)}

							{frontmatter.linkedin === null ? null : (
								<a
									href={frontmatter.linkedin}
									className={style.socialLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										className={style.socialIcon}
										alt="LinkedIn Icon"
										src={LinkedInIcon}
									/>
								</a>
							)}

							{frontmatter.instagram === null ? null : (
								<a
									href={frontmatter.instagram}
									className={style.socialLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										className={style.socialIcon}
										alt="Instagram Icon"
										src={InstagramIcon}
									/>
								</a>
							)}
						</div>

						<div className={style.projectContainer}>
							{data.projects ? (
								data.projects.edges.map(({ node: project }) => (
									<div
										className={style.projectLink}
										key={project.id}
									>
										<Link to={project.frontmatter.path}>
											<Img
												fluid={
													project.frontmatter.cover
														.childImageSharp.fluid
												}
											/>
											<h3 className={style.projTitle}>
												{project.frontmatter.title}
											</h3>
											<h5 className={style.category}>
												{project.frontmatter.category}
											</h5>
										</Link>
									</div>
								))
							) : (
								<div>This student has no projects :( </div>
							)}
						</div>

						<section className={style.wrapper}>
							<article className={style.graduates}>
								<h2 className={style.header}>
									Explore More Graduates
								</h2>
								<GraduatesBanner />
							</article>
						</section>

						<div className={style.bannerContainer}>
							<Banner
								title="Like What You See?"
								subtitle="Meet the grads behind these projects In Real Life at the grad show on April 10 & 11 at Free Space!"
								cta="View Details"
							/>
						</div>
					</div>
				)}
			</Spring>
		</Layout>
	)
}

export const pageQuery = graphql`
	query GraduatesPage($path: String!, $name: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			html
			frontmatter {
				path
				name
				portfolio
				cover {
					childImageSharp {
						fluid(maxWidth: 480, cropFocus: CENTER) {
							...GatsbyImageSharpFluid
						}
					}
				}
				linkedin
				email
				twitter
				instagram
				medium
				dribble
				behance
				interestArea
				where
				relocation
				bio
				field
			}
		}

		projects: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { glob: "**/src/content/work/*.md" }
				frontmatter: { graduate: { regex: $name } }
			}
		) {
			edges {
				node {
					id
					frontmatter {
						path
						title
						graduate
						category
						cover {
							childImageSharp {
								fluid(maxWidth: 480, cropFocus: CENTER) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`
