import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import style from './style.module.less'

export default class Banner extends PureComponent {
	render() {
		const { title, subtitle, cta } = this.props

		return (
			<div className={style.bannerGlobal}>
				<div className={style.comeMeetTheCastBackground} />
				<div className={style.comeMeetTheCastBackgroundBottom} />
				<article className={style.gridLayout}>
					<div className={style.bannerContent}>
						<h3 className={style.title}>{title}</h3>
						<p className={style.subtitle}>{subtitle}</p>
						<Link to="/about/" className={style.cta}>
							{cta}
						</Link>
					</div>
				</article>
			</div>
		)
	}
}

Banner.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	cta: PropTypes.string,
}
